import Vue from 'vue'
import Vuex from 'vuex'
import IdleVue from 'idle-vue'

const store = new Vuex.Store({
  state: {
    isIdle: false
  }
})
const eventsHub = new Vue()

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  // 3 minutes
  idleTime: 180000,
  startAtIdle: false,
  events: ['mousemove', 'keydown', 'mousedown', 'touchstart', 'scroll']
})
