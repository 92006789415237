import HttpRepository from './httpRepository'

export default class BoardRepository extends HttpRepository {
  getBoardItems(
    params: {
      limit?: number
      offset?: number
      type?: 'official' | 'ad'
      roles?: string[]
    } = {
      limit: 2000,
      offset: 0,
      type: undefined,
      roles: undefined
    }
  ): Promise<any> {
    return this.post('/api/board/getBoardItems', params)
  }

  getBoardAds(
    params: {
      limit?: number
      offset?: number
      displayedItems?: string[]
    } = {
      limit: 2000,
      offset: 0,
      displayedItems: []
    }
  ): Promise<any> {
    return this.post('/api/board/getBoardAds', params)
  }
}
