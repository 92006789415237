import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _36ec029a = () => interopDefault(import('../pages/add-home.vue' /* webpackChunkName: "pages/add-home" */))
const _043d2467 = () => interopDefault(import('../pages/agree-terms.vue' /* webpackChunkName: "pages/agree-terms" */))
const _3bcdcb81 = () => interopDefault(import('../pages/confirm.vue' /* webpackChunkName: "pages/confirm" */))
const _0cf33cf1 = () => interopDefault(import('../pages/doctor/index.vue' /* webpackChunkName: "pages/doctor/index" */))
const _27e36083 = () => interopDefault(import('../pages/group/index.vue' /* webpackChunkName: "pages/group/index" */))
const _8d637306 = () => interopDefault(import('../pages/hospitals/index.vue' /* webpackChunkName: "pages/hospitals/index" */))
const _b343812a = () => interopDefault(import('../pages/invitation/index.vue' /* webpackChunkName: "pages/invitation/index" */))
const _2eaccdea = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _7505b30d = () => interopDefault(import('../pages/mypage/index.vue' /* webpackChunkName: "pages/mypage/index" */))
const _7528f55f = () => interopDefault(import('../pages/notices/index.vue' /* webpackChunkName: "pages/notices/index" */))
const _bd939f06 = () => interopDefault(import('../pages/notification/index.vue' /* webpackChunkName: "pages/notification/index" */))
const _158f7f14 = () => interopDefault(import('../pages/passwordSetting.vue' /* webpackChunkName: "pages/passwordSetting" */))
const _79e49b70 = () => interopDefault(import('../pages/posting_information.vue' /* webpackChunkName: "pages/posting_information" */))
const _31ddd1ea = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _38cb4f38 = () => interopDefault(import('../pages/regist-confirm.vue' /* webpackChunkName: "pages/regist-confirm" */))
const _263ac877 = () => interopDefault(import('../pages/retry-register.vue' /* webpackChunkName: "pages/retry-register" */))
const _404efc88 = () => interopDefault(import('../pages/rooms/index.vue' /* webpackChunkName: "pages/rooms/index" */))
const _3141ee12 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _19724732 = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _0f02e908 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _2fdc4f2e = () => interopDefault(import('../pages/unreceived_auth_code.vue' /* webpackChunkName: "pages/unreceived_auth_code" */))
const _270d5f23 = () => interopDefault(import('../pages/welcome.vue' /* webpackChunkName: "pages/welcome" */))
const _e5f1d060 = () => interopDefault(import('../pages/business-card/holder/index.vue' /* webpackChunkName: "pages/business-card/holder/index" */))
const _6b10e4b0 = () => interopDefault(import('../pages/business-card/requests/index.vue' /* webpackChunkName: "pages/business-card/requests/index" */))
const _33192cd7 = () => interopDefault(import('../pages/contact/complete.vue' /* webpackChunkName: "pages/contact/complete" */))
const _4030e312 = () => interopDefault(import('../pages/contact/confirm.vue' /* webpackChunkName: "pages/contact/confirm" */))
const _1784fde2 = () => interopDefault(import('../pages/contact/form.vue' /* webpackChunkName: "pages/contact/form" */))
const _14f287c2 = () => interopDefault(import('../pages/doctor/new/index.vue' /* webpackChunkName: "pages/doctor/new/index" */))
const _3d333a99 = () => interopDefault(import('../pages/doctor/search.vue' /* webpackChunkName: "pages/doctor/search" */))
const _b30906de = () => interopDefault(import('../pages/group/new.vue' /* webpackChunkName: "pages/group/new" */))
const _03117f1c = () => interopDefault(import('../pages/hospitals/edit/index.vue' /* webpackChunkName: "pages/hospitals/edit/index" */))
const _1c39628d = () => interopDefault(import('../pages/hospitals/search.vue' /* webpackChunkName: "pages/hospitals/search" */))
const _487cd6b0 = () => interopDefault(import('../pages/invitation/complete.vue' /* webpackChunkName: "pages/invitation/complete" */))
const _35d82643 = () => interopDefault(import('../pages/lists/hospitals/index.vue' /* webpackChunkName: "pages/lists/hospitals/index" */))
const _25e273a7 = () => interopDefault(import('../pages/lists/new.vue' /* webpackChunkName: "pages/lists/new" */))
const _3f21b803 = () => interopDefault(import('../pages/medical/confirm.vue' /* webpackChunkName: "pages/medical/confirm" */))
const _3531be6c = () => interopDefault(import('../pages/medical/profile.vue' /* webpackChunkName: "pages/medical/profile" */))
const _193b50b6 = () => interopDefault(import('../pages/medical/signup.vue' /* webpackChunkName: "pages/medical/signup" */))
const _987333ae = () => interopDefault(import('../pages/migration/complete.vue' /* webpackChunkName: "pages/migration/complete" */))
const _4b079e1c = () => interopDefault(import('../pages/migration/registered.vue' /* webpackChunkName: "pages/migration/registered" */))
const _6e007f65 = () => interopDefault(import('../pages/ms/group.vue' /* webpackChunkName: "pages/ms/group" */))
const _083da935 = () => interopDefault(import('../pages/ms/search/index.vue' /* webpackChunkName: "pages/ms/search/index" */))
const _5120f006 = () => interopDefault(import('../pages/mypage/search.vue' /* webpackChunkName: "pages/mypage/search" */))
const _fccb4da0 = () => interopDefault(import('../pages/notices/new/index.vue' /* webpackChunkName: "pages/notices/new/index" */))
const _2ceccfec = () => interopDefault(import('../pages/rooms/new.vue' /* webpackChunkName: "pages/rooms/new" */))
const _0978ce1e = () => interopDefault(import('../pages/setting/mail_magazine.vue' /* webpackChunkName: "pages/setting/mail_magazine" */))
const _5844ed1b = () => interopDefault(import('../pages/top/medical/index.vue' /* webpackChunkName: "pages/top/medical/index" */))
const _141f3022 = () => interopDefault(import('../pages/top/ms.vue' /* webpackChunkName: "pages/top/ms" */))
const _b450aea0 = () => interopDefault(import('../pages/doctor/new/confirm.vue' /* webpackChunkName: "pages/doctor/new/confirm" */))
const _7370bedc = () => interopDefault(import('../pages/doctor/new/profile/index.vue' /* webpackChunkName: "pages/doctor/new/profile/index" */))
const _067b6188 = () => interopDefault(import('../pages/doctor/new/select.vue' /* webpackChunkName: "pages/doctor/new/select" */))
const _0545ac6a = () => interopDefault(import('../pages/group/member/add.vue' /* webpackChunkName: "pages/group/member/add" */))
const _7983f2be = () => interopDefault(import('../pages/hospitals/edit/bed.vue' /* webpackChunkName: "pages/hospitals/edit/bed" */))
const _aa4d215c = () => interopDefault(import('../pages/hospitals/edit/medical_staff.vue' /* webpackChunkName: "pages/hospitals/edit/medical_staff" */))
const _09b4d01f = () => interopDefault(import('../pages/hospitals/edit/open_bed/index.vue' /* webpackChunkName: "pages/hospitals/edit/open_bed/index" */))
const _1913f34a = () => interopDefault(import('../pages/hospitals/edit/outpatient/index.vue' /* webpackChunkName: "pages/hospitals/edit/outpatient/index" */))
const _e536ff90 = () => interopDefault(import('../pages/hospitals/edit/patient_date.vue' /* webpackChunkName: "pages/hospitals/edit/patient_date" */))
const _f71a71a6 = () => interopDefault(import('../pages/hospitals/edit/result.vue' /* webpackChunkName: "pages/hospitals/edit/result" */))
const _4ff518d2 = () => interopDefault(import('../pages/lists/hospitals/add.vue' /* webpackChunkName: "pages/lists/hospitals/add" */))
const _2514ab8e = () => interopDefault(import('../pages/notices/new/select.vue' /* webpackChunkName: "pages/notices/new/select" */))
const _a424aea6 = () => interopDefault(import('../pages/doctor/new/profile/doctorExpertCategory.vue' /* webpackChunkName: "pages/doctor/new/profile/doctorExpertCategory" */))
const _e90e3ebe = () => interopDefault(import('../pages/doctor/new/profile/pending.vue' /* webpackChunkName: "pages/doctor/new/profile/pending" */))
const _25b56119 = () => interopDefault(import('../pages/doctor/new/profile/searchPaperMeshList.vue' /* webpackChunkName: "pages/doctor/new/profile/searchPaperMeshList" */))
const _65e8d5ca = () => interopDefault(import('../pages/doctor/new/profile/surgeryMeshList.vue' /* webpackChunkName: "pages/doctor/new/profile/surgeryMeshList" */))
const _5f914fec = () => interopDefault(import('../pages/doctor/new/profile/unacceptableMeshList.vue' /* webpackChunkName: "pages/doctor/new/profile/unacceptableMeshList" */))
const _c8d0c27c = () => interopDefault(import('../pages/hospitals/edit/outpatient/edit.vue' /* webpackChunkName: "pages/hospitals/edit/outpatient/edit" */))
const _87220216 = () => interopDefault(import('../pages/doctor/new/profile/editHospital/_id.vue' /* webpackChunkName: "pages/doctor/new/profile/editHospital/_id" */))
const _6bb36372 = () => interopDefault(import('../pages/hospitals/edit/open_bed/_id.vue' /* webpackChunkName: "pages/hospitals/edit/open_bed/_id" */))
const _248de1a5 = () => interopDefault(import('../pages/group/notice/_id/index.vue' /* webpackChunkName: "pages/group/notice/_id/index" */))
const _229e452a = () => interopDefault(import('../pages/lists/hospitals/_id.vue' /* webpackChunkName: "pages/lists/hospitals/_id" */))
const _2d12775c = () => interopDefault(import('../pages/doctor/_id/index.vue' /* webpackChunkName: "pages/doctor/_id/index" */))
const _454281ee = () => interopDefault(import('../pages/group/_id/index.vue' /* webpackChunkName: "pages/group/_id/index" */))
const _63c5c425 = () => interopDefault(import('../pages/hospitals/_id.vue' /* webpackChunkName: "pages/hospitals/_id" */))
const _8e89beda = () => interopDefault(import('../pages/invitation/_id.vue' /* webpackChunkName: "pages/invitation/_id" */))
const _c3ece1ba = () => interopDefault(import('../pages/ms/_id/index.vue' /* webpackChunkName: "pages/ms/_id/index" */))
const _cc8b6e6c = () => interopDefault(import('../pages/notices/_id/index.vue' /* webpackChunkName: "pages/notices/_id/index" */))
const _18a02e25 = () => interopDefault(import('../pages/notification/_id.vue' /* webpackChunkName: "pages/notification/_id" */))
const _64588ee1 = () => interopDefault(import('../pages/papers/_id.vue' /* webpackChunkName: "pages/papers/_id" */))
const _623b42b2 = () => interopDefault(import('../pages/rooms/_id/index.vue' /* webpackChunkName: "pages/rooms/_id/index" */))
const _ab4c7a1a = () => interopDefault(import('../pages/doctor/_id/edit/index.vue' /* webpackChunkName: "pages/doctor/_id/edit/index" */))
const _946aa8c4 = () => interopDefault(import('../pages/group/_id/edit.vue' /* webpackChunkName: "pages/group/_id/edit" */))
const _0285bbb0 = () => interopDefault(import('../pages/group/_id/history.vue' /* webpackChunkName: "pages/group/_id/history" */))
const _f5b1459e = () => interopDefault(import('../pages/group/_id/member/index.vue' /* webpackChunkName: "pages/group/_id/member/index" */))
const _626520e8 = () => interopDefault(import('../pages/ms/_id/edit/index.vue' /* webpackChunkName: "pages/ms/_id/edit/index" */))
const _6ea1a645 = () => interopDefault(import('../pages/notices/_id/edit/index.vue' /* webpackChunkName: "pages/notices/_id/edit/index" */))
const _0ccfd3c5 = () => interopDefault(import('../pages/rooms/_id/edit.vue' /* webpackChunkName: "pages/rooms/_id/edit" */))
const _c924d79e = () => interopDefault(import('../pages/rooms/_id/members/index.vue' /* webpackChunkName: "pages/rooms/_id/members/index" */))
const _27773126 = () => interopDefault(import('../pages/doctor/_id/edit/category.vue' /* webpackChunkName: "pages/doctor/_id/edit/category" */))
const _337713b6 = () => interopDefault(import('../pages/doctor/_id/edit/doctorExpertCategory.vue' /* webpackChunkName: "pages/doctor/_id/edit/doctorExpertCategory" */))
const _58de70bd = () => interopDefault(import('../pages/doctor/_id/edit/email.vue' /* webpackChunkName: "pages/doctor/_id/edit/email" */))
const _634f5619 = () => interopDefault(import('../pages/doctor/_id/edit/papers/index.vue' /* webpackChunkName: "pages/doctor/_id/edit/papers/index" */))
const _113df0f0 = () => interopDefault(import('../pages/doctor/_id/edit/searchPaperMeshList.vue' /* webpackChunkName: "pages/doctor/_id/edit/searchPaperMeshList" */))
const _e39986c2 = () => interopDefault(import('../pages/doctor/_id/edit/specialty.vue' /* webpackChunkName: "pages/doctor/_id/edit/specialty" */))
const _7e2e2dbe = () => interopDefault(import('../pages/doctor/_id/edit/surgeryMeshList.vue' /* webpackChunkName: "pages/doctor/_id/edit/surgeryMeshList" */))
const _35ca8a16 = () => interopDefault(import('../pages/doctor/_id/edit/unacceptableMeshList.vue' /* webpackChunkName: "pages/doctor/_id/edit/unacceptableMeshList" */))
const _275ebafd = () => interopDefault(import('../pages/group/_id/notice/new.vue' /* webpackChunkName: "pages/group/_id/notice/new" */))
const _4e11fdce = () => interopDefault(import('../pages/notices/_id/edit/select.vue' /* webpackChunkName: "pages/notices/_id/edit/select" */))
const _0b6dab80 = () => interopDefault(import('../pages/rooms/_id/members/add.vue' /* webpackChunkName: "pages/rooms/_id/members/add" */))
const _45e0acf4 = () => interopDefault(import('../pages/doctor/_id/edit/papers/delete.vue' /* webpackChunkName: "pages/doctor/_id/edit/papers/delete" */))
const _0c428f18 = () => interopDefault(import('../pages/doctor/_id/edit/hospitals/_index.vue' /* webpackChunkName: "pages/doctor/_id/edit/hospitals/_index" */))
const _25176275 = () => interopDefault(import('../pages/rooms/_id/medias/_mediaId.vue' /* webpackChunkName: "pages/rooms/_id/medias/_mediaId" */))
const _65052ad3 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/add-home",
    component: _36ec029a,
    name: "add-home"
  }, {
    path: "/agree-terms",
    component: _043d2467,
    name: "agree-terms"
  }, {
    path: "/confirm",
    component: _3bcdcb81,
    name: "confirm"
  }, {
    path: "/doctor",
    component: _0cf33cf1,
    name: "doctor"
  }, {
    path: "/group",
    component: _27e36083,
    name: "group"
  }, {
    path: "/hospitals",
    component: _8d637306,
    name: "hospitals"
  }, {
    path: "/invitation",
    component: _b343812a,
    name: "invitation"
  }, {
    path: "/login",
    component: _2eaccdea,
    name: "login"
  }, {
    path: "/mypage",
    component: _7505b30d,
    name: "mypage"
  }, {
    path: "/notices",
    component: _7528f55f,
    name: "notices"
  }, {
    path: "/notification",
    component: _bd939f06,
    name: "notification"
  }, {
    path: "/passwordSetting",
    component: _158f7f14,
    name: "passwordSetting"
  }, {
    path: "/posting_information",
    component: _79e49b70,
    name: "posting_information"
  }, {
    path: "/profile",
    component: _31ddd1ea,
    name: "profile"
  }, {
    path: "/regist-confirm",
    component: _38cb4f38,
    name: "regist-confirm"
  }, {
    path: "/retry-register",
    component: _263ac877,
    name: "retry-register"
  }, {
    path: "/rooms",
    component: _404efc88,
    name: "rooms"
  }, {
    path: "/search",
    component: _3141ee12,
    name: "search"
  }, {
    path: "/signup",
    component: _19724732,
    name: "signup"
  }, {
    path: "/terms",
    component: _0f02e908,
    name: "terms"
  }, {
    path: "/unreceived_auth_code",
    component: _2fdc4f2e,
    name: "unreceived_auth_code"
  }, {
    path: "/welcome",
    component: _270d5f23,
    name: "welcome"
  }, {
    path: "/business-card/holder",
    component: _e5f1d060,
    name: "business-card-holder"
  }, {
    path: "/business-card/requests",
    component: _6b10e4b0,
    name: "business-card-requests"
  }, {
    path: "/contact/complete",
    component: _33192cd7,
    name: "contact-complete"
  }, {
    path: "/contact/confirm",
    component: _4030e312,
    name: "contact-confirm"
  }, {
    path: "/contact/form",
    component: _1784fde2,
    name: "contact-form"
  }, {
    path: "/doctor/new",
    component: _14f287c2,
    name: "doctor-new"
  }, {
    path: "/doctor/search",
    component: _3d333a99,
    name: "doctor-search"
  }, {
    path: "/group/new",
    component: _b30906de,
    name: "group-new"
  }, {
    path: "/hospitals/edit",
    component: _03117f1c,
    name: "hospitals-edit"
  }, {
    path: "/hospitals/search",
    component: _1c39628d,
    name: "hospitals-search"
  }, {
    path: "/invitation/complete",
    component: _487cd6b0,
    name: "invitation-complete"
  }, {
    path: "/lists/hospitals",
    component: _35d82643,
    name: "lists-hospitals"
  }, {
    path: "/lists/new",
    component: _25e273a7,
    name: "lists-new"
  }, {
    path: "/medical/confirm",
    component: _3f21b803,
    name: "medical-confirm"
  }, {
    path: "/medical/profile",
    component: _3531be6c,
    name: "medical-profile"
  }, {
    path: "/medical/signup",
    component: _193b50b6,
    name: "medical-signup"
  }, {
    path: "/migration/complete",
    component: _987333ae,
    name: "migration-complete"
  }, {
    path: "/migration/registered",
    component: _4b079e1c,
    name: "migration-registered"
  }, {
    path: "/ms/group",
    component: _6e007f65,
    name: "ms-group"
  }, {
    path: "/ms/search",
    component: _083da935,
    name: "ms-search"
  }, {
    path: "/mypage/search",
    component: _5120f006,
    name: "mypage-search"
  }, {
    path: "/notices/new",
    component: _fccb4da0,
    name: "notices-new"
  }, {
    path: "/rooms/new",
    component: _2ceccfec,
    name: "rooms-new"
  }, {
    path: "/setting/mail_magazine",
    component: _0978ce1e,
    name: "setting-mail_magazine"
  }, {
    path: "/top/medical",
    component: _5844ed1b,
    name: "top-medical"
  }, {
    path: "/top/ms",
    component: _141f3022,
    name: "top-ms"
  }, {
    path: "/doctor/new/confirm",
    component: _b450aea0,
    name: "doctor-new-confirm"
  }, {
    path: "/doctor/new/profile",
    component: _7370bedc,
    name: "doctor-new-profile"
  }, {
    path: "/doctor/new/select",
    component: _067b6188,
    name: "doctor-new-select"
  }, {
    path: "/group/member/add",
    component: _0545ac6a,
    name: "group-member-add"
  }, {
    path: "/hospitals/edit/bed",
    component: _7983f2be,
    name: "hospitals-edit-bed"
  }, {
    path: "/hospitals/edit/medical_staff",
    component: _aa4d215c,
    name: "hospitals-edit-medical_staff"
  }, {
    path: "/hospitals/edit/open_bed",
    component: _09b4d01f,
    name: "hospitals-edit-open_bed"
  }, {
    path: "/hospitals/edit/outpatient",
    component: _1913f34a,
    name: "hospitals-edit-outpatient"
  }, {
    path: "/hospitals/edit/patient_date",
    component: _e536ff90,
    name: "hospitals-edit-patient_date"
  }, {
    path: "/hospitals/edit/result",
    component: _f71a71a6,
    name: "hospitals-edit-result"
  }, {
    path: "/lists/hospitals/add",
    component: _4ff518d2,
    name: "lists-hospitals-add"
  }, {
    path: "/notices/new/select",
    component: _2514ab8e,
    name: "notices-new-select"
  }, {
    path: "/doctor/new/profile/doctorExpertCategory",
    component: _a424aea6,
    name: "doctor-new-profile-doctorExpertCategory"
  }, {
    path: "/doctor/new/profile/pending",
    component: _e90e3ebe,
    name: "doctor-new-profile-pending"
  }, {
    path: "/doctor/new/profile/searchPaperMeshList",
    component: _25b56119,
    name: "doctor-new-profile-searchPaperMeshList"
  }, {
    path: "/doctor/new/profile/surgeryMeshList",
    component: _65e8d5ca,
    name: "doctor-new-profile-surgeryMeshList"
  }, {
    path: "/doctor/new/profile/unacceptableMeshList",
    component: _5f914fec,
    name: "doctor-new-profile-unacceptableMeshList"
  }, {
    path: "/hospitals/edit/outpatient/edit",
    component: _c8d0c27c,
    name: "hospitals-edit-outpatient-edit"
  }, {
    path: "/doctor/new/profile/editHospital/:id?",
    component: _87220216,
    name: "doctor-new-profile-editHospital-id"
  }, {
    path: "/hospitals/edit/open_bed/:id",
    component: _6bb36372,
    name: "hospitals-edit-open_bed-id"
  }, {
    path: "/group/notice/:id",
    component: _248de1a5,
    name: "group-notice-id"
  }, {
    path: "/lists/hospitals/:id",
    component: _229e452a,
    name: "lists-hospitals-id"
  }, {
    path: "/doctor/:id",
    component: _2d12775c,
    name: "doctor-id"
  }, {
    path: "/group/:id",
    component: _454281ee,
    name: "group-id"
  }, {
    path: "/hospitals/:id",
    component: _63c5c425,
    name: "hospitals-id"
  }, {
    path: "/invitation/:id",
    component: _8e89beda,
    name: "invitation-id"
  }, {
    path: "/ms/:id",
    component: _c3ece1ba,
    name: "ms-id"
  }, {
    path: "/notices/:id",
    component: _cc8b6e6c,
    name: "notices-id"
  }, {
    path: "/notification/:id",
    component: _18a02e25,
    name: "notification-id"
  }, {
    path: "/papers/:id?",
    component: _64588ee1,
    name: "papers-id"
  }, {
    path: "/rooms/:id",
    component: _623b42b2,
    name: "rooms-id"
  }, {
    path: "/doctor/:id/edit",
    component: _ab4c7a1a,
    name: "doctor-id-edit"
  }, {
    path: "/group/:id/edit",
    component: _946aa8c4,
    name: "group-id-edit"
  }, {
    path: "/group/:id/history",
    component: _0285bbb0,
    name: "group-id-history"
  }, {
    path: "/group/:id/member",
    component: _f5b1459e,
    name: "group-id-member"
  }, {
    path: "/ms/:id?/edit",
    component: _626520e8,
    name: "ms-id-edit"
  }, {
    path: "/notices/:id/edit",
    component: _6ea1a645,
    name: "notices-id-edit"
  }, {
    path: "/rooms/:id/edit",
    component: _0ccfd3c5,
    name: "rooms-id-edit"
  }, {
    path: "/rooms/:id/members",
    component: _c924d79e,
    name: "rooms-id-members"
  }, {
    path: "/doctor/:id/edit/category",
    component: _27773126,
    name: "doctor-id-edit-category"
  }, {
    path: "/doctor/:id/edit/doctorExpertCategory",
    component: _337713b6,
    name: "doctor-id-edit-doctorExpertCategory"
  }, {
    path: "/doctor/:id/edit/email",
    component: _58de70bd,
    name: "doctor-id-edit-email"
  }, {
    path: "/doctor/:id/edit/papers",
    component: _634f5619,
    name: "doctor-id-edit-papers"
  }, {
    path: "/doctor/:id/edit/searchPaperMeshList",
    component: _113df0f0,
    name: "doctor-id-edit-searchPaperMeshList"
  }, {
    path: "/doctor/:id/edit/specialty",
    component: _e39986c2,
    name: "doctor-id-edit-specialty"
  }, {
    path: "/doctor/:id/edit/surgeryMeshList",
    component: _7e2e2dbe,
    name: "doctor-id-edit-surgeryMeshList"
  }, {
    path: "/doctor/:id/edit/unacceptableMeshList",
    component: _35ca8a16,
    name: "doctor-id-edit-unacceptableMeshList"
  }, {
    path: "/group/:id/notice/new",
    component: _275ebafd,
    name: "group-id-notice-new"
  }, {
    path: "/notices/:id/edit/select",
    component: _4e11fdce,
    name: "notices-id-edit-select"
  }, {
    path: "/rooms/:id/members/add",
    component: _0b6dab80,
    name: "rooms-id-members-add"
  }, {
    path: "/doctor/:id/edit/papers/delete",
    component: _45e0acf4,
    name: "doctor-id-edit-papers-delete"
  }, {
    path: "/doctor/:id/edit/hospitals/:index",
    component: _0c428f18,
    name: "doctor-id-edit-hospitals"
  }, {
    path: "/rooms/:id/medias/:mediaId?",
    component: _25176275,
    name: "rooms-id-medias-mediaId"
  }, {
    path: "/",
    component: _65052ad3,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
