export const setStorage = (
  key: string,
  value: string | number | string[] | Array<any>,
  expire: number | undefined
): void => {
  let data
  if (expire !== undefined) {
    data = {
      expire,
      value
    }
  } else {
    data = value
  }
  localStorage.setItem(key, JSON.stringify(data))
}

export const getStorage = (
  key: string
): string | number | string[] | undefined => {
  let s:
    | string
    | null
    | {
        expire: number
        value: string | number
      } = localStorage.getItem(key)
  if (s === undefined || s === null) {
    return undefined
  }
  s = JSON.parse(s)

  if (!s) return ''

  if (typeof s === 'string' || typeof s === 'number' || Array.isArray(s)) {
    return s
  }

  if (new Date(s.expire) > new Date()) {
    return s.value
  } else {
    localStorage.removeItem(key)
    return undefined
  }
}

export const getDisplayedObjectIds = (
  storageKey: string,
  userObjectId: string
):
  | {
      objectIds: string[]
      lastObjectId: string
    }
  | undefined => {
  const data =
    ((getStorage(storageKey) as unknown) as {
      userObjectId: string
      displayedObjectIds: string[]
      lastObjectId: string
    }[]) || []
  const user = data.find(item => item.userObjectId === userObjectId)
  return user
    ? {
        objectIds: user.displayedObjectIds,
        lastObjectId: user.lastObjectId
      }
    : {
        objectIds: [],
        lastObjectId: ''
      }
}

export const setDisplayedObjectIds = (
  storageKey: string,
  userObjectId: string,
  displayedObjectIds: string[]
): void => {
  const data =
    ((getStorage(storageKey) as unknown) as {
      userObjectId: string
      displayedObjectIds: string[]
      lastObjectId: string
    }[]) || []
  const userIndex = data.findIndex(item => item.userObjectId === userObjectId)
  if (userIndex !== -1) {
    const user =
      data[userIndex] ??
      ({} as {
        userObjectId: string
        displayedObjectIds: string[]
        lastObjectId: string
      })
    user.displayedObjectIds = displayedObjectIds
    user.lastObjectId =
      displayedObjectIds && displayedObjectIds.length > 0
        ? displayedObjectIds[displayedObjectIds.length - 1] || ''
        : ''
  } else {
    data.push({
      userObjectId,
      displayedObjectIds,
      lastObjectId: ''
    })
  }

  setStorage(storageKey, data, undefined)
}

export const clearDisplayedObjectIds = (
  storageKey: string,
  userObjectId: string
): void => {
  const data =
    ((getStorage(storageKey) as unknown) as {
      userObjectId: string
      displayedObjectIds: string[]
      lastObjectId: string
    }[]) || []
  const userIndex = data.findIndex(item => item.userObjectId === userObjectId)

  if (userIndex !== -1) {
    const user =
      data[userIndex] ??
      ({} as {
        userObjectId: string
        displayedObjectIds: string[]
        lastObjectId: string
      })
    user.displayedObjectIds = []
  } else {
    data.push({
      userObjectId,
      displayedObjectIds: [],
      lastObjectId: ''
    })
  }

  setStorage(storageKey, data, undefined)
}
