import firebase from 'firebase/app'
import get from 'lodash.get'
import { FirebaseRepository } from '~/plugins/repository/firebaseRepository'
import constant from '~/helper/constant'
import 'firebase/analytics'

const firebaseConfig = constant.firebase

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

export default (context, inject) => {
  const { app } = context
  const firebaseRepository = new FirebaseRepository(firebase)
  context.$firebaseRepository = firebaseRepository
  inject('firebaseRepository', firebaseRepository)

  if (app.store.state.currentUser && app.store.state.doctorCategory) {
    let hospitalName = null
    let hospitalPrefecture = null
    let hospitalCity = null

    // 医師の場合
    if (
      app.store.state.currentUser.role === 'doctor' &&
      app.store.state.profile &&
      app.store.state.profile.hospitals
    ) {
      const hospitals = app.store.state.profile.hospitals
      hospitalName = get(hospitals, '[0].name', null)
      hospitalPrefecture = get(hospitals, '[0].prefecture', null)
      hospitalCity = get(hospitals, '[0].city', null)
    }

    // 医療機関アカウントの場合
    if (
      app.store.state.currentUser.role === 'co-medical' &&
      app.store.state.profile &&
      app.store.state.profile.belongTo
    ) {
      const hospital = app.store.state.profile.belongTo
      hospitalName = get(hospital, 'name', null)
      hospitalPrefecture = get(hospital, 'prefecture', null)
      hospitalCity = get(hospital, 'city', null)
    }

    // MSの場合は、nullのまま送信する

    // router.pushに置き換える時にlogin時に呼ぶ用にする
    firebaseRepository.setUserProperties(
      app.store.state.currentUser,
      app.store.state.doctorCategory,
      hospitalName,
      hospitalPrefecture,
      hospitalCity
    )
  }

  app.router.afterEach(to => {
    firebaseRepository.viewEvent(to, app.store)
  })
}
